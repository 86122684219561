:root {
  --bg-color-primary: black;
  --bg-color-secondary: rgb(74, 72, 72);
  --text-color-primary: white;
}

nav {
  background-color: var(--bg-color-primary);
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 100;
}

.navItem-Container {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navLogo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 100%;
}

.navLogo {
  height: 60%;
  padding: 0 10px;
}

.navLogo-name {
  padding: 0;
  margin: 0;
}

.navItem {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2em;
  text-decoration: none;
  color: white !important;
}

.navItem:hover {
  background-color: var(--bg-color-secondary);
  cursor: pointer;
  transition-duration: 0.3s;
}

.navItem.active {
  background-color: var(--bg-color-secondary);
}

.burgermenu-bars-container,
.burgermenu-button {
  height: 30px;
  width: 30px;
  z-index: 1001;
  position: fixed;
  right: 20px;
  top: 10px;
  background-color: transparent;
}

.burgermenu-bars {
  background-color: var(--text-color-primary);
  display: inline-block;
  height: 3px;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  right: 0px;
  opacity: 1;
  transition-duration: 500ms;
  z-index: 1001;
}

/* .burgermenu-bars-container:hover .burgermenu-bars  */

.burgermenu-button:hover {
  cursor: pointer;
}

.burgermenu-bars-container:hover .burgermenu-bars {
  background-color: var(--bg-color-secondary);
}

.openedmenu.burgermenu-bars-container:hover .burgermenu-bars {
  background-color: var(--bg-color-primary);
}

.cross-1 {
  transform: rotate(45deg) translateY(17px);
  transition-duration: 500ms;
}

.cross-2 {
  visibility: hidden;
  transition-duration: 1ms;
}

.cross-3 {
  transform: rotate(-45deg) translateY(-17px);
  transition-duration: 500ms;
}

.burgermenu-button {
  opacity: 0;
  z-index: 1002;
}

.burgermenu {
  height: 100vh;
  width: 300px;
  z-index: 1000;
  background-color: var(--bg-color-secondary);
  position: fixed;
  top: 0;
  right: -300px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  /*ggf hier center/flex-start wählen! */
  align-items: flex-start;
  justify-content: flex-start;
  transform: translateX(300px);
  transition-duration: 500ms;
}

.opened {
  transform: translateX(-300px);
  transition-duration: 500ms;
}

.openedmenu {
  transition-duration: 500ms;
}

/* öffnen/schliessen */
.instr {
  background-color: var(--bg-color-primary);
  position: relative;
  top: 5px;
  right: 11px;
  text-align: center;
  z-index: 1001;
  color: var(--text-color-primary);
  transition-duration: 500ms;
  font-size: 0.8em;
}

.openedmenu.instr {
  background-color: var(--bg-color-secondary);
}

.burgermenu-bars-container:hover .instr {
  color: var(--bg-color-secondary);
}

.openedmenu.burgermenu-bars-container:hover .instr {
  color: var(--bg-color-secondary);
}

.burgermenu-listItems {
  list-style: none;
  padding: 6%;
  background-color: var(--bg-color-secondary);
}

.burgermenu-listItems a {
  text-decoration: none;
  font-size: 1.5em;
  background-color: var(--bg-color-secondary);
}

/* can be adapted to customers wishes ! */
.burgermenu-listItems a:hover {
  text-decoration: underline;
  text-underline-offset: 6px;
  color: var(--text-color-secondary);
}

.overlay {
  background-color: rgba(50, 46, 48, 0.3);
  width: 100%;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed; /* Sit on top of the page content */
}

@media screen and (min-width: 1000px) {
  .burgermenu {
    visibility: hidden;
  }

  .burgermenu-bars-container {
    visibility: hidden;
  }
}

@media screen and (max-width: 1000px) {
  .navItem {
    visibility: hidden;
  }
  .navLogo-name {
    visibility: hidden;
  }
}
